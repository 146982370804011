<template>
  <q-carousel
    v-model="cameraSlide"
    transition-prev="scale"
    transition-next="scale"
    swipeable
    animated
    infinite
    height="100%"
    class="bg-transparent"
  >
    <q-carousel-slide name="default">
      <div class="video">
        <video id="video" />
      </div>
    </q-carousel-slide>

    <q-carousel-slide name="image">
      <q-uploader
        style="width: 100%; height: 89%; max-height: 100%;"
        :label="$t('Select image')"
        accept=".jpg, image/*"
        hide-upload-btn
        @rejected="onRejected"
        @added="onAddImage"
      />
    </q-carousel-slide>
  </q-carousel>
</template>

<script>
import * as ZXing from '@zxing/library'

export default {
  name: 'BarcodeScanner',
  emits: ['change'],
  data () {
    return {
      cameraSlide: 'default',
      codeReader: new ZXing.BrowserMultiFormatReader()
    }
  },
  watch: {
    cameraSlide (value) {
      if (value === 'default') {
        this.startVideo()
      } else {
        this.codeReader.reset()
      }
    }
  },
  mounted () {
    this.startVideo()
  },
  unmounted () {
    this.codeReader.reset()
  },
  methods: {
    onRejected (error) {
      console.debug(error)
    },
    onAddImage (files) {
      const blob = URL.createObjectURL(files[0])
      const img = document.createElement('img')
      img.src = blob

      return this.codeReader.decodeFromImage(img)
        .then(result => {
          if (result) {
            this.$emit('change', result.text)
          }
        })
        .catch(err => {
          console.debug(err)
        })
    },
    startVideo () {
      return this.codeReader.decodeFromVideoDevice(undefined, 'video', (result, err) => {
        if (result) {
          this.$emit('change', result.text)
        }
      })
    }
  }
}
</script>
